import React from "react";
import {Nav, NavItem, Navbar, NavDropdown, MenuItem} from 'react-bootstrap';
import "./nav.css";

export default class Navigation extends React.Component {

    constructor(props) {
        super(props);
        this.language = window.location.pathname === '/en' ? 'en' :
            window.location.pathname === '/de' ? 'de' : 'hu';


        this.texts = {
            en: {
                description: 'Description',
                gallery: 'Gallery',
                contact: 'Contact'
            },
            de: {
                description: 'Beschreibung',
                gallery: 'Galerie',
                contact: 'Kontakt'
            },
            hu: {
                description: 'Leírás',
                gallery: 'Galéria',
                contact: 'Kapcsolat'
            },

        }
    }


    render() {
        return (
            <Navbar collapseOnSelect>
                <Navbar.Header>
                    <Navbar.Brand>
                        <a className="header-brand" href="#brand">Adél Apartman</a>
                    </Navbar.Brand>
                    <Navbar.Toggle/>
                </Navbar.Header>
                <Navbar.Collapse>
                    <Nav pullRight>
                        <NavItem eventKey={1} href="#about">
                            {this.texts[this.language].description}
                        </NavItem>
                        <NavItem eventKey={2} href="#gallery">
                            {this.texts[this.language].gallery}
                        </NavItem>
                        <NavItem eventKey={4} href="#map">
                            {this.texts[this.language].contact}
                        </NavItem>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        );
    }

}