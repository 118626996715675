import React, {Component} from 'react';
import './App.css';
import Navigation from './nav/nav';
import Cover from './cover/cover';
import {StickyContainer, Sticky} from 'react-sticky';
import Gallery from './content/gallery';
import {Grid} from 'react-bootstrap';

class De extends Component {
    render() {
        return (


            <div className="App">

                <Cover opacity={0.4}/>

                <StickyContainer>
                    <Sticky>
                        {
                            ({isSticky}) => <div style={{
                                position: isSticky ? 'fixed' : 'relative',
                                top: 0,
                                width: '100%',
                                zIndex: 2,
                                color: 'red'
                            }}><Navigation/></div>
                        }
                    </Sticky>

                    <Grid>
                        <div className="itemContainer" id="about">

                            <div className="item">
                                <div className="ti-Line-Map-Pin-3 item-icon col-md-2"></div>
                                <div className="col-md-10">Unsere Wohnung liegt im grünen Gürtel von Sárvár, leicht
                                    befindet sich in einer zugänglichen, ruhigen Straße.
                                    In der Nähe des Stadtzentrums und des Spas. Im Umkreis von 200 Metern
                                    Lebensmittelgeschäft (Penny, Spar), Restaurant, Spielplatz,
                                    Busbahnhof, Markt (Montag, Sonntag).
                                </div>
                            </div>

                            <div className="item">
                                <div className="ti-Line-Home item-icon col-md-2"></div>
                                <div className="col-md-10">Auf 65 m2 bieten wir eine komfortable Unterkunft für bis zu 7-8 Personen.
                                    Unsere 2-Zimmer-Wohnung ist in erster Linie ideal für Familien.
                                    Beide Zimmer haben einen Fernseher für unsere Gäste.
                                </div>
                            </div>

                            <div className="item">
                                <div className="ti-Line-Fire item-icon col-md-2"></div>
                                <div className="col-md-10">Große, gut ausgestattete Küche für unsere Gäste
                                    vorhanden (Kühlschrank, Mikrowelle, Gasherd, Toaster, Kaffeemaschine, Wasserkocher,
                                    Teller, Geschirr). In der Wohnung steht Ihnen unbegrenzt kostenloses WIFI zur Verfügung.
                                    Das Parken ist im geschlossenen Hof möglich, wo es eine Schaukel, ein Klettergerüst, einen Pavillon,
                                    Es gibt auch einen Speckofen und einen Wasserkocher. Unsere Gäste reisen mit dem Zug an
                                    (kostenlos) zur Unterkunft geliefert.
                                </div>
                            </div>

                            <div className="item">
                                <div className="ti-Line-People-1 item-icon col-md-2"></div>
                                <div className="col-md-10">Unsere Wohnung ist ideal für Familien bis zu 3 Personen
                                    Generation ebenso
                                    Bei uns können Sie angenehme Tage verbringen. Aber wir warten ein paar Tage, Paare,
                                    unsere Gäste, die in der Gegend arbeiten oder reisen. Wir bleiben auch für 1 Nacht
                                    wir freuen uns auf Sie (außer Juli, August und Sonderfeiertage)!
                                </div>
                            </div>

                            <div className="item">
                                <div className="ti-Line-Credit-Card item-icon col-md-2"></div>
                                <div className="col-md-10">
                                    Unsere Gäste haben die Möglichkeit zu bezahlen
                                    mit Bargeld u
                                    per Banküberweisung.
                                </div>
                            </div>
                        </div>

                        <Gallery/>

                        <div className="map-container">
                            <div id="map" className="col-xs-12 col-md-8">
                                <h2>Kontakt</h2>
                                <iframe title="gmap"
                                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2708.245271770587!2d16.92133131530702!3d47.25090697916275!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47694ce6bf1a7d4d%3A0x189f66796c5329ae!2zU8OhcnbDoXIsIE9yc29seWEgdS4gMjIsIDk2MDA!5e0!3m2!1shu!2shu!4v1531331170597"
                                        width="100%" height="450px" frameBorder="none"
                                        allowFullScreen></iframe>
                            </div>

                            <div className="col-xs-12 col-md-4 contact">
                                <h4>Kontakte (nur ungarisch)</h4>
                                <p> Zsolnai Adél </p>
                                <p className="contact-data">
                                    <span className="ti-Line-Phone"></span>
                                    +36 70/36 56 418
                                </p>
                                <p className="contact-data">
                                    <span className="ti-Line-Email"></span>
                                    <a href="mailto:name@email.com">adelzsolnai@gmail.com</a>
                                </p>

                                <h4 className="mt-50">Englisch Kontakt</h4>
                                <p> Gábor Zsolnai </p>
                                <p className="contact-data">
                                    <span className="ti-Line-Phone"></span>
                                    +36 20/91 24 553
                                </p>
                                <p className="contact-data">
                                    <span className="ti-Line-Email"></span>
                                    <a href="mailto:name@email.com">zsolnai.gabor@gmail.com</a>
                                </p>

                                <p className="ntak-number">
                                    NTAK Registrationsnummer: MA19016965 - Apartman
                                </p>
                            </div>
                            <div className="clearfix"></div>
                        </div>
                    </Grid>

                </StickyContainer>
            </div>


        );

    }
}

export default De;
