import React, {Component} from 'react';
import './App.css';
import Navigation from './nav/nav';
import Cover from './cover/cover';
import {StickyContainer, Sticky} from 'react-sticky';
import Gallery from './content/gallery';
import {Grid} from 'react-bootstrap';

class Hu extends Component {
    render() {
        return (


            <div className="App">

                <Cover opacity={0.4}/>

                <StickyContainer>
                    <Sticky>
                        {
                            ({isSticky}) => <div style={{
                                position: isSticky ? 'fixed' : 'relative',
                                top: 0,
                                width: '100%',
                                zIndex: 2,
                                color: 'red'
                            }}><Navigation/></div>
                        }
                    </Sticky>

                    <Grid>
                        <div className="itemContainer" id="about">

                            <div className="item">
                                <div className="ti-Line-Map-Pin-3 item-icon col-md-2"></div>
                                <div className="col-md-10">Apartmanunk Sárvár zöldövetezi részén, könnyen
                                    megközelíthető, csendes utcában helyezkedik el.
                                    Közel a városközponthoz és a wellness fürdőhöz. 200 méteres körzetben
                                    élelmiszerbolt (Penny, Spar), étterem, játszótér,
                                    autóbusz pályaudvar, piac (hétfő, vasárnap) található.
                                </div>
                            </div>

                            <div className="item">
                                <div className="ti-Line-Home item-icon col-md-2"></div>
                                <div className="col-md-10">
                                    65 m2-en akár 7-8 fő részére tudunk kényelmes elhelyezést biztosítani.
                                    A 2 szobás apartmanunk elsősorban családok számára ideális.
                                    Mindkét szobában tv és légkondicíonálló áll vendégeink rendelkezésére.
                                </div>
                            </div>

                            <div className="item">
                                <div className="ti-Line-Fire item-icon col-md-2"></div>
                                <div className="col-md-10">Vendégeink részére nagy méretű, jól felszerelt konyha
                                    áll rendelkezésre (hűtő, mikró, gáztűzhely, kenyérpirító, kávéfőző, vízforraló,
                                    tányérok, edények). Az apartman területén korlátlan, ingyenes WIFI biztosított.
                                    Parkolásra a zárt udvarban van lehetőség, ahol hinta, mászóka, kerti pavilon,
                                    szalonnasütő, bográcsozó is található. Vonattal érkező vendégeinket
                                    (díjmentesen) a szállásra szállítjuk.
                                </div>
                            </div>

                            <div className="item">
                                <div className="ti-Line-People-1 item-icon col-md-2"></div>
                                <div className="col-md-10">Apartmanunk családok számára ideális, akár 3
                                    generáció is
                                    kellemes napokat tölthet el nálunk. De várjuk pár napra érkező, párok,
                                    környéken dolgozó vagy átutazó vendégeinket is. Mi 1 éjszakára is
                                    szeretettel várjuk (kivéve július, augusztus és a kiemelt ünnepek)!
                                </div>
                            </div>

                            <div className="item">
                                <div className="ti-Line-Credit-Card item-icon col-md-2"></div>
                                <div className="col-md-10">Vendégeinknek lehetőségük van fizetni
                                    készpénzzel,
                                    átutalással illetve SZÉP kártyával.
                                </div>
                            </div>
                        </div>

                        <Gallery/>

                        <div className="map-container">
                            <div id="map" className="col-xs-12 col-md-8">
                                <h2>Kapcsolat</h2>
                                <iframe title="gmap"
                                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2708.245271770587!2d16.92133131530702!3d47.25090697916275!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47694ce6bf1a7d4d%3A0x189f66796c5329ae!2zU8OhcnbDoXIsIE9yc29seWEgdS4gMjIsIDk2MDA!5e0!3m2!1shu!2shu!4v1531331170597"
                                        width="100%" height="450px" frameBorder="none"
                                        allowFullScreen></iframe>
                            </div>

                            <div className="col-xs-12 col-md-4 contact">
                                <h4>Elérhetőségek</h4>
                                <p> Zsolnai Adél </p>
                                <p className="contact-data">
                                    <span className="ti-Line-Phone"></span>
                                    +36 70/36 56 418
                                </p>
                                <p className="contact-data">
                                    <span className="ti-Line-Email"></span>
                                    <a href="mailto:name@email.com">adelzsolnai@gmail.com</a>
                                </p>

                                <p className="ntak-number">
                                    NTAK regisztrációs szám: MA19016965 - Apartman
                                </p>
                            </div>
                            <div className="clearfix"></div>
                        </div>
                    </Grid>

                </StickyContainer>
            </div>


        );

    }
}

export default Hu;
