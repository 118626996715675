import React, {Component} from 'react';
import './App.css';
import Navigation from './nav/nav';
import Cover from './cover/cover';
import {StickyContainer, Sticky} from 'react-sticky';
import Gallery from './content/gallery';
import {Grid} from 'react-bootstrap';

class De extends Component {
    render() {
        return (


            <div className="App">

                <Cover opacity={0.4}/>

                <StickyContainer>
                    <Sticky>
                        {
                            ({isSticky}) => <div style={{
                                position: isSticky ? 'fixed' : 'relative',
                                top: 0,
                                width: '100%',
                                zIndex: 2,
                                color: 'red'
                            }}><Navigation/></div>
                        }
                    </Sticky>

                    <Grid>
                        <div className="itemContainer" id="about">

                            <div className="item">
                                <div className="ti-Line-Map-Pin-3 item-icon col-md-2"></div>
                                <div className="col-md-10">Our apartment is located in the green belt of Sárvár, easily
                                    is located in an accessible, quiet street.
                                    Close to the city center and the spa. Within a radius of 200 meters
                                    grocery store (Penny, Spar), restaurant, playground,
                                    bus station, market (Monday, Sunday).
                                </div>
                            </div>

                            <div className="item">
                                <div className="ti-Line-Home item-icon col-md-2"></div>
                                <div className="col-md-10">We can comfortably accommodate up to 6-7 people on 55 m2
                                    provide accommodation. The 4 air spaces (bedroom, living room,
                                    kitchen, bathroom) apartment is ideal for families.
                                    If required, two separate sleeping areas can be provided.
                                    There is a TV and AC available in both rooms for our guests.
                                </div>
                            </div>

                            <div className="item">
                                <div className="ti-Line-Fire item-icon col-md-2"></div>
                                <div className="col-md-10">
                                    Large and well equipped for our guests
                                    kitchen
                                    available (fridge, microwave, gas stove, toaster, coffee maker,
                                    plates, dishes).
                                    Unlimited free internet access (WIFI) in the apartment area
                                    provided.
                                    Parking is possible in the closed yard, where there is a swing, climbing frame, garden
                                    There is also a gazebo, a bacon oven and a kettle.
                                    Guests arriving by train are delivered to the accommodation (free of charge).
                                </div>
                            </div>

                            <div className="item">
                                <div className="ti-Line-People-1 item-icon col-md-2"></div>
                                <div className="col-md-10">Our apartment is ideal for families up to 3
                                    generation as well
                                    you can spend pleasant days with us. But we are waiting for a few days to arrive, couples,
                                    our guests working or traveling in the area. We also stay for 1 night
                                    we look forward to seeing you!
                                </div>
                            </div>

                            <div className="item">
                                <div className="ti-Line-Credit-Card item-icon col-md-2"></div>
                                <div className="col-md-10">Our guests have the opportunity to pay
                                    with cash
                                    by bank transfer or SZÉP card.
                                </div>
                            </div>
                        </div>

                        <Gallery/>

                        <div className="map-container">
                            <div id="map" className="col-xs-12 col-md-8">
                                <h2>Kapcsolat</h2>
                                <iframe title="gmap"
                                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2708.245271770587!2d16.92133131530702!3d47.25090697916275!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47694ce6bf1a7d4d%3A0x189f66796c5329ae!2zU8OhcnbDoXIsIE9yc29seWEgdS4gMjIsIDk2MDA!5e0!3m2!1shu!2shu!4v1531331170597"
                                        width="100%" height="450px" frameBorder="none"
                                        allowFullScreen></iframe>
                            </div>

                            <div className="col-xs-12 col-md-4 contact">
                                <h4 className="mt-50">Contact</h4>
                                <p> Gábor Zsolnai </p>
                                <p className="contact-data">
                                    <span className="ti-Line-Phone"></span>
                                    +36 20/91 24 553
                                </p>
                                <p className="contact-data">
                                    <span className="ti-Line-Email"></span>
                                    <a href="mailto:name@email.com">zsolnai.gabor@gmail.com</a>
                                </p>

                                <p className="ntak-number">
                                    NTAK registration number: MA19016965 - Apartman
                                </p>
                            </div>
                            <div className="clearfix"></div>
                        </div>
                    </Grid>

                </StickyContainer>
            </div>


        );

    }
}

export default De;
