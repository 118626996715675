import React from 'react';
import LazyHero from 'react-lazy-hero';
import "./cover.css";

const getImageUrl = (index) => `/assets/cover/adelapartman_cover${index}-min.jpg`;

export default class Cover extends React.Component {
 
  setImage(min, max) {
    const random = Math.floor(Math.random()*(max-min)+1);
    return getImageUrl(random);
  }

  render() {
    return(
      <LazyHero className="cover-container"
                color="#333"
                minHeight="60vh"
                opacity={this.props.opacity}
                imageSrc={this.setImage(1,4)}>
        <h1 className="cover-text">Adél Apartman</h1>
        <div className="language-picker">
          <a href="/">HU</a> | <a href={'/en'}>EN</a> | <a href={'/de'}>DE</a>
        </div>
      </LazyHero>
    );
  }
}
