import React from 'react';
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css";
import "./gallery.css";
 
class Gallery extends React.Component {
 
  render() {
 
    const images = [
      {
        original: '/assets/cover/gallery01-min.jpg',
        thumbnail: '/assets/cover/gallery01-min.jpg',
      },
      {
        original: '/assets/cover/gallery02-min.jpg',
        thumbnail: '/assets/cover/gallery02-min.jpg'
      },
      {
        original: '/assets/cover/gallery03-min.jpg',
        thumbnail: '/assets/cover/gallery03-min.jpg'
      },
      {
        original: '/assets/cover/gallery04-min.jpg',
        thumbnail: '/assets/cover/gallery04-min.jpg'
      },
      {
        original: '/assets/cover/gallery05-min.jpg',
        thumbnail: '/assets/cover/gallery05-min.jpg'
      },
      {
        original: '/assets/cover/gallery06-min.jpg',
        thumbnail: '/assets/cover/gallery06-min.jpg'
      },
      {
        original: '/assets/cover/gallery07-min.jpg',
        thumbnail: '/assets/cover/gallery07-min.jpg'
      },
      {
        original: '/assets/cover/gallery08-min.jpg',
        thumbnail: '/assets/cover/gallery08-min.jpg'
      },
      {
        original: '/assets/cover/gallery09-min.jpg',
        thumbnail: '/assets/cover/gallery09-min.jpg'
      },
      {
        original: '/assets/cover/gallery10-min.jpg',
        thumbnail: '/assets/cover/gallery10-min.jpg'
      },
      {
        original: '/assets/cover/gallery11-min.jpg',
        thumbnail: '/assets/cover/gallery11-min.jpg'
      },
      {
        original: '/assets/cover/gallery12-min.jpg',
        thumbnail: '/assets/cover/gallery12-2-min.jpg'
      },
      {
        original: '/assets/cover/gallery13-min.jpg',
        thumbnail: '/assets/cover/gallery13-min.jpg'
      },
      {
        original: '/assets/cover/gallery14-min.jpg',
        thumbnail: '/assets/cover/gallery14-min.jpg'
      },
      {
        original: '/assets/cover/gallery15-min.jpg',
        thumbnail: '/assets/cover/gallery15-min.jpg'
      },
      {
        original: '/assets/cover/gallery16-min.jpg',
        thumbnail: '/assets/cover/gallery16-min.jpg'
      },
      {
        original: '/assets/cover/gallery20-min.jpg',
        thumbnail: '/assets/cover/gallery20-min.jpg'
      },
      {
        original: '/assets/cover/gallery21-min.jpg',
        thumbnail: '/assets/cover/gallery21-min.jpg'
      },
    ]
 
    return (
      <div id="gallery">
        <h2>Galéria</h2>
        <ImageGallery items={images} useBrowserFullscreen={false} showFullscreenButton={false} showPlayButton={false} />
      </div>
    );
  }

}
export default Gallery;