import React, {Component} from 'react';
import './App.css';

import {BrowserRouter, Route, Routes} from 'react-router-dom'
import Hu from "./hu";
import En from "./en";
import De from "./de";

class App extends Component {
    render() {
        return (
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<Hu></Hu>}> </Route>
                    <Route path="/en" element={<En></En>}> </Route>
                    <Route path="/de" element={<De></De>}> </Route>

                </Routes>
            </BrowserRouter>

        );

    }
}

export default App;
